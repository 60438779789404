import { useMemo } from 'react';

import { TourProps } from 'antd';
import { ONBOARDING_GUIDE_MODULES } from 'shared/enums/onboardingGuideModules';
import { TRefsObject } from 'shared/types/onboardingTypes';

import { TOnboardingConfig } from '../hooks/useOnboardingConfigMap';
import { getFilteredSteps } from '../lib';

export const useDiagramsPartTwoOnboardingConfig = (refs: TRefsObject): TOnboardingConfig => {
	return useMemo(() => {
		const steps: TourProps['steps'] = [
			{
				title: 'Action Buttons',
				placement: 'right',
				description: <p>Copy, paste, undo, and redo.</p>,
			},
			{
				title: 'Action Button',
				placement: 'left',
				description: <p>Expand to full screen.</p>,
			},
			{
				title: 'Shapes',
				placement: 'right',
				description: <p>Add basic flow chart shapes.</p>,
			},
			{
				title: 'Shapes',
				placement: 'right',
				description: <p>Add other shapes and attach images.</p>,
			},

			{
				title: 'Settings',
				placement: 'bottomLeft',
				description: <p>Adjust settings for shapes and lines.</p>,
			},
		];

		const mappedSteps: TourProps['steps'] = getFilteredSteps({
			steps,
			refs,
			module: ONBOARDING_GUIDE_MODULES.DIAGRAMS_2,
		});

		return { steps: mappedSteps };
	}, [refs]);
};
