import { useEffect } from 'react';

import { ONBOARDING_GUIDE_MODULES } from 'shared/enums/onboardingGuideModules';

import OnboardingConsumer from '../onboardingContext';

const useStartOnboarding = (module: ONBOARDING_GUIDE_MODULES, shouldStart = true): void => {
	const { handleStartOnboarding } = OnboardingConsumer() || {};

	useEffect(() => {
		if (shouldStart) {
			handleStartOnboarding?.(module);
		}
	}, [handleStartOnboarding, module, shouldStart]);
};

export default useStartOnboarding;
