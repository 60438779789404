import { useEffect } from 'react';

import { useSearchParams } from 'react-router-dom';

const useGetMicrosoftAuthCode = (): void => {
	const [searchParams] = useSearchParams();

	useEffect(() => {
		const code = searchParams.get('code');

		if (code) {
			localStorage.setItem('code', code);
			window.close();
		}
	}, [searchParams]);
};

export default useGetMicrosoftAuthCode;
