import {
	FunctionComponent,
	ReactElement,
	createContext,
	useContext,
	useEffect,
	useState,
} from 'react';

export type TConfluenceOauthContextReturnType = {
	handleConfluenceLogin: () => void;
	resetAuthCode: () => void;
	confluenceAuthCode: string;
};

const ConfluenceOauthContext = createContext<TConfluenceOauthContextReturnType>(undefined!);

export const ConfluenceOauthProvider: FunctionComponent<{ children: ReactElement }> = ({
	children,
}) => {
	// @ts-ignore
	const [confluenceAuthCode, setConfluenceAuthCode] = useState<string>(null);

	const handleConfluenceLogin = (): void => {
		// @ts-ignore
		const clientId = (): string => process.env.REACT_APP_CONFLUENCE_CLIENT_ID;
		const redirectUri = (): string =>
			// @ts-ignore
			encodeURIComponent(process.env.REACT_APP_CONFLUENCE_REDIRECT_URL);
		const scope = encodeURIComponent(
			'offline_access read:jira-work read:jira-user write:jira-work manage:jira-webhook read:workflow:jira',
		);

		const authUrl = `https://auth.atlassian.com/authorize?client_id=${clientId()}&scope=${scope}&redirect_uri=${redirectUri()}&response_type=code&prompt=consent`;

		window.open(authUrl);
	};

	const resetAuthCode = (): void => {
		// @ts-ignore
		setConfluenceAuthCode(null);
	};

	useEffect(() => {
		const handleStorageChange = (event: StorageEvent): void => {
			if (event.key === 'code') {
				const authCode = event.newValue;
				if (authCode) {
					setConfluenceAuthCode(authCode);
					localStorage.removeItem('code');
				}
			}
		};

		window.addEventListener('storage', handleStorageChange);

		return () => {
			window.removeEventListener('storage', handleStorageChange);
		};
	}, []);

	return (
		<ConfluenceOauthContext.Provider
			value={{ handleConfluenceLogin, confluenceAuthCode, resetAuthCode }}
		>
			{children}
		</ConfluenceOauthContext.Provider>
	);
};

export default function ConfluenceOauthConsumer(): TConfluenceOauthContextReturnType {
	const context = useContext(ConfluenceOauthContext);

	if (context === undefined) {
		throw new Error('ConfluenceOauthConsumer must be used within a ConfluenceOauthProvider');
	}
	return context;
}
