import { TourStepProps } from 'antd';
import { ONBOARDING_GUIDE_MODULES } from 'shared/enums/onboardingGuideModules';
import { TRefsObject } from 'shared/types/onboardingTypes';

type TGetFilteredSteps = (params: {
	steps: TourStepProps[];
	refs: TRefsObject;
	module: ONBOARDING_GUIDE_MODULES;
}) => TourStepProps[];

export const getFilteredSteps: TGetFilteredSteps = ({ steps, refs, module }) => {
	return steps
		.map((step, index) => ({
			target: refs?.get(`${module}-${index + 1}`)?.current,
			style: {
				width: '100%',
			},
			prevButtonProps: {
				children: 'Back',
				style: {
					border: 'none',
					color: 'var(--mainGray)',
				},
			},
			...step,
		}))
		.filter((step) => !!step.target);
};
