import { useMemo } from 'react';

import { Divider, TourProps } from 'antd';
import { ONBOARDING_GUIDE_MODULES } from 'shared/enums/onboardingGuideModules';
import { TRefsObject } from 'shared/types/onboardingTypes';

import { TOnboardingConfig } from '../hooks/useOnboardingConfigMap';
import { getFilteredSteps } from '../lib';
import styles from '../ui/ProfileMenu.module.scss';

export const useHomeWalkthroughOnboardingConfig = (refs: TRefsObject): TOnboardingConfig => {
	return useMemo(() => {
		const steps: TourProps['steps'] = [
			{
				title: 'Profile',
				placement: 'bottomRight',
				description: <p>To see and edit your profile settings you can click this button.</p>,
			},
			{
				title: 'Profile Menu',
				placement: 'left',
				description: (
					<>
						<p>In your profile you can:</p>
						<ul className={styles.profileMenuList}>
							<li>Edit personal information</li>
							<li>Add users</li>
							<li>Create engagements</li>
							<li>Manage, payments, plan and more.</li>
						</ul>
						<Divider
							style={{
								margin: '8px 0',
							}}
						/>
					</>
				),
			},
			{
				title: 'Home',
				placement: 'bottomRight',
				description: <p>The home button will return you to the home screen.</p>,
			},
			{
				title: 'Notes',
				placement: 'bottomRight',
				description: (
					<p>
						Capture your notes in one place. Take notes, store screenshots, create diagrams and
						more.
					</p>
				),
			},
			{
				title: 'Chat',
				description: <p>Turn the chat feature on/off.</p>,
				placement: 'bottomRight',
			},
		];

		const mappedSteps: TourProps['steps'] = getFilteredSteps({
			steps,
			refs,
			module: ONBOARDING_GUIDE_MODULES.HOME_WALKTHROUGH,
		});

		return {
			steps: mappedSteps,
			stepsWithAction: [0, 1],
		};
	}, [refs]);
};
