import { useMemo } from 'react';

import { TourProps } from 'antd';
import { ONBOARDING_GUIDE_MODULES } from 'shared/enums/onboardingGuideModules';
import { TRefsObject } from 'shared/types/onboardingTypes';

import { TOnboardingConfig } from '../hooks/useOnboardingConfigMap';
import { getFilteredSteps } from '../lib';

export const useOperatingModelOnboardingConfig = (refs: TRefsObject): TOnboardingConfig => {
	return useMemo(() => {
		const steps: TourProps['steps'] = [
			{
				title: 'Table',
				placement: 'top',
				description: <p>New operating models will display here.</p>,
			},
			{
				title: 'Button',
				placement: 'left',
				description: <p>Create new operating models here.</p>,
			},
			{
				title: 'Action Button',
				placement: 'bottomRight',
				description: <p>Manage archived operating models.</p>,
			},
			{
				title: 'Action Button',
				placement: 'bottomRight',
				description: <p>Edit operating model details here.</p>,
			},
		];

		const mappedSteps: TourProps['steps'] = getFilteredSteps({
			steps,
			refs,
			module: ONBOARDING_GUIDE_MODULES.OPERATING_MODEL,
		});

		return { steps: mappedSteps };
	}, [refs]);
};
