import { useMemo } from 'react';

import { TourProps } from 'antd';
import { ONBOARDING_GUIDE_MODULES } from 'shared/enums/onboardingGuideModules';
import { TRefsObject } from 'shared/types/onboardingTypes';

import { TOnboardingConfig } from '../hooks/useOnboardingConfigMap';
import { getFilteredSteps } from '../lib';

export const useWorkspacesOnboardingConfig = (refs: TRefsObject): TOnboardingConfig => {
	return useMemo(() => {
		const steps: TourProps['steps'] = [
			{
				title: 'Table',
				placement: 'top',
				description: (
					<p>Each Initiative created will be listed here. The titles will have a hyperlink.</p>
				),
			},
			{
				title: 'Button',
				placement: 'bottomRight',
				description: (
					<p>Add an initiative or let the AI guide you through building an initiative.</p>
				),
			},
			{
				title: 'Action Button',
				placement: 'bottomRight',
				description: <p>Manage tags and archive.</p>,
			},
			{
				title: 'Action Button',
				placement: 'bottomRight',
				description: <p>Edit/delete/archive an initiative or pin to the side menu.</p>,
			},
			{
				title: 'Side Bar',
				placement: 'right',
				description: <p>Pin initiatives to the side menu.</p>,
			},
		];

		const mappedSteps: TourProps['steps'] = getFilteredSteps({
			steps,
			refs,
			module: ONBOARDING_GUIDE_MODULES.WORKSPACES,
		});

		return { steps: mappedSteps, stepsWithAction: [3, 4] };
	}, [refs]);
};
