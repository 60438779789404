import { useMemo } from 'react';

import { TourProps } from 'antd';
import { ONBOARDING_GUIDE_MODULES } from 'shared/enums/onboardingGuideModules';
import AuthConsumer from 'shared/guards/auth.guard';
import { TRefsObject } from 'shared/types/onboardingTypes';

import { TOnboardingConfig } from '../hooks/useOnboardingConfigMap';
import { getFilteredSteps } from '../lib';

export const useOptimizationOnboardingConfig = (refs: TRefsObject): TOnboardingConfig => {
	const { user } = AuthConsumer();

	return useMemo(() => {
		const steps: TourProps['steps'] = [
			{
				title: 'Table',
				placement: 'top',
				description: <p>New optimizations are listed in this table.</p>,
			},
			{
				title: 'Button',
				placement: 'left',
				description: <p>Create an optimization or leverage AI support.</p>,
			},
			{
				title: 'Action Button',
				placement: 'left',
				description: <p>Edit optimization details and view reports/data.</p>,
				target: user?.isCanBeEditOptimizationPage
					? refs?.get(`${ONBOARDING_GUIDE_MODULES.OPTIMIZATION}-3`)?.current
					: null,
			},
		];

		const mappedSteps: TourProps['steps'] = getFilteredSteps({
			steps,
			refs,
			module: ONBOARDING_GUIDE_MODULES.OPTIMIZATION,
		});

		return { steps: mappedSteps };
	}, [refs, user]);
};
