import { FC, PropsWithChildren } from 'react';

import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import classNames from 'classnames';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export type TWithLoaderProps = PropsWithChildren & {
	loading: boolean;
	error?: boolean;
	center?: boolean;
	blockCenter?: boolean;
	isData?: boolean;
	minHeight?: number | string;
};

const WithLoader: FC<TWithLoaderProps> = (props): JSX.Element => {
	const {
		loading,
		error,
		children,
		center,
		blockCenter,
		isData = false,
		minHeight = 'unset',
	} = props;

	if (loading && !isData) {
		const classEl = classNames('preloader-container', { center, blockCenter });
		return (
			<div
				className={classEl}
				style={{ minHeight }}
				key="loading-data"
				data-cy="preloader-container"
			>
				<Spin indicator={antIcon} />
			</div>
		);
	}

	if (!loading && error) {
		return (
			<div className="no-data" key="no-data" data-cy="no-data">
				No data available
			</div>
		);
	}

	const classWrapper = classNames('loading-wrapper', {
		_loading: loading && isData,
	});

	return <div className={classWrapper}>{children}</div>;
};

export default WithLoader;
