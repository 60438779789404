import { useEffect } from 'react';

import { message } from 'antd';
import { useSearchParams } from 'react-router-dom';

const MessageDisplayer = (): null => {
	const [searchParams, setSearchParams] = useSearchParams();
	const getErrorMsg = searchParams.get('error');

	useEffect(() => {
		if (getErrorMsg) {
			message.error(getErrorMsg);
			searchParams.delete('error');
			setSearchParams(searchParams);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return null;
};

export default MessageDisplayer;
