export enum SUBSCRIPTIONS_PLANS {
	PLAN = 'PLAN',
	BUILD = 'BUILD',
	GROW = 'GROW',
	ENTERPRISE = 'ENTERPRISE',
}

export type TSubscriptionsPlan = keyof typeof SUBSCRIPTIONS_PLANS;

export type TSubscriptionsPlans = TSubscriptionsPlan[];
