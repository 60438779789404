import { useMemo } from 'react';

import { TourProps } from 'antd';
import { ONBOARDING_GUIDE_MODULES } from 'shared/enums/onboardingGuideModules';
import { TRefsObject } from 'shared/types/onboardingTypes';

import { TOnboardingConfig } from '../hooks/useOnboardingConfigMap';
import { getFilteredSteps } from '../lib';

export const useProjectsPartOneOnboardingConfig = (refs: TRefsObject): TOnboardingConfig => {
	return useMemo(() => {
		const steps: TourProps['steps'] = [
			{
				title: 'Table',
				placement: 'top',
				description: <p>All projects are listed here in priority order.</p>,
			},
			{
				title: 'Button',
				placement: 'left',
				description: (
					<p>
						Create a new project or leverage AI to create a project portfolio from plans,
						optimizations, and other data in Corvus Link.
					</p>
				),
			},
			{
				title: 'Action Button',
				placement: 'left',
				description: <p>Edit project details here.</p>,
			},
			{
				title: 'Navigation',
				placement: 'bottom',
				description: <p>Link projects to programs.</p>,
			},
			{
				title: 'Navigation',
				placement: 'bottom',
				description: <p>Link programs to portfolios.</p>,
			},
			{
				title: 'Navigation',
				placement: 'bottom',
				description: <p>View milestone timelines for all projects.</p>,
			},
			{
				title: 'Navigation',
				placement: 'bottom',
				description: <p>View charts showing project, program, and portfolio performance.</p>,
			},
			{
				title: 'Navigation',
				placement: 'right',
				description: <p>Create guidance and other portfolio materials.</p>,
			},
		];

		const mappedSteps: TourProps['steps'] = getFilteredSteps({
			steps,
			refs,
			module: ONBOARDING_GUIDE_MODULES.PROJECTS_1,
		});

		return { steps: mappedSteps };
	}, [refs]);
};
