import { useMemo } from 'react';

import { TourProps } from 'antd';
import { ONBOARDING_GUIDE_MODULES } from 'shared/enums/onboardingGuideModules';
import { TRefsObject } from 'shared/types/onboardingTypes';

import { TOnboardingConfig } from '../hooks/useOnboardingConfigMap';
import { getFilteredSteps } from '../lib';

export const useForecastPartTwoOnboardingConfig = (refs: TRefsObject): TOnboardingConfig => {
	return useMemo(() => {
		const steps: TourProps['steps'] = [
			{
				title: 'Filter',
				placement: 'bottomRight',
				description: <p>Filter results.</p>,
			},
			{
				title: 'Action Button',
				placement: 'bottomLeft',
				description: <p>View Analysis or adjust forecast.</p>,
			},
		];

		const mappedSteps: TourProps['steps'] = getFilteredSteps({
			steps,
			refs,
			module: ONBOARDING_GUIDE_MODULES.FORECAST_2,
		});

		return { steps: mappedSteps, stepsWithAction: [1] };
	}, [refs]);
};
