import {
	MutableRefObject,
	ReactElement,
	ReactNode,
	createContext,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';

import { useIsFetching, useMutation } from '@tanstack/react-query';
import { Tour, message } from 'antd';
import { startCase, toLower } from 'lodash';
import { completeOnboarding } from 'shared/api/users.service';
import { ONBOARDING_GUIDE_MODULES } from 'shared/enums/onboardingGuideModules';
import { ERoutes } from 'shared/enums/routes';
import AuthConsumer from 'shared/guards/auth.guard';
import { useIsIncludesInPathname } from 'shared/hooks/useIsIncludesInPathname';
import { TOnboardingRefKey, TRefsObject } from 'shared/types/onboardingTypes';

import useOnboardingConfigMap from './hooks/useOnboardingConfigMap';

export type TOnboardingGuideModule = keyof typeof ONBOARDING_GUIDE_MODULES;
type THandleStepInit = <T>(params: { refKey: TOnboardingRefKey; ref: MutableRefObject<T> }) => void;

export type TOnboardingContextReturnType = {
	handleStartOnboarding: (value: TOnboardingGuideModule) => void;
	handleStepInit: THandleStepInit;
	onClose: VoidFunction;
	isStepWithAction?: boolean;
	module: TOnboardingGuideModule | undefined;
};

const OnboardingContext = createContext<TOnboardingContextReturnType | undefined>(undefined);

export const OnboardingProvider = ({ children }: { children: ReactNode }): ReactElement => {
	const [module, setModule] = useState<ONBOARDING_GUIDE_MODULES>();
	const [currentStep, setCurrentStep] = useState<number>();
	const [nextStep, setNextStep] = useState<number>();
	const [refs, setRefs] = useState<TRefsObject>(new Map());

	const isFetching = useIsFetching();

	const onClose = useCallback(() => {
		setModule(undefined);
		setCurrentStep(undefined);
		setNextStep(undefined);
		setRefs(new Map());
	}, []);

	const { refetchUser } = AuthConsumer();
	const { mutateAsync } = useMutation(
		(value: ONBOARDING_GUIDE_MODULES) => completeOnboarding(value),
		{
			onSettled: onClose,
		},
	);

	const config = useOnboardingConfigMap(refs);

	const {
		steps = [],
		stepsWithAction = [],
		stepsWithAllowedInteractions = [],
		stepsWithHiddenIndicators = [],
	} = module ? config[module] || {} : {};
	const isNextStepInitialized = !!steps?.[nextStep ?? 0]?.target;

	useEffect(() => {
		if (nextStep !== currentStep && isNextStepInitialized) {
			requestAnimationFrame(() => setCurrentStep(nextStep));
		}
	}, [currentStep, isNextStepInitialized, nextStep, steps]);

	const handleStepChange = (current: number): void => {
		setNextStep(current);
	};

	const indicatorRender = (current: number, total: number): ReactElement | null => {
		let label = startCase(toLower(module?.replace(/_/g, ' ').replace(/\d/g, '')));

		if (module === ONBOARDING_GUIDE_MODULES.SIDE_MENU) {
			label = 'General';
		}

		return stepsWithHiddenIndicators.includes(currentStep ?? 0) ? null : (
			<span style={{ color: '#006162' }}>
				{label}: {current + 1}/{total}
			</span>
		);
	};

	const onFinish = async (): Promise<void> => {
		try {
			module && (await mutateAsync(module));
			refetchUser();
		} catch (e) {
			message.error(e?.response?.data?.message || e.message || 'Error!');
		}
	};

	const handleStartOnboarding = useCallback((value: ONBOARDING_GUIDE_MODULES): void => {
		setTimeout(() => {
			setModule(value);
			setNextStep(0);
		}, 500);
	}, []);

	const value = useMemo(() => {
		const handleStepInit: THandleStepInit = ({ refKey, ref }) => {
			setRefs((prev) => {
				if (!prev.has(refKey)) {
					return new Map(prev).set(refKey, ref);
				}

				return prev;
			});
		};

		return {
			handleStartOnboarding,
			handleStepInit,
			isStepWithAction: stepsWithAction?.includes(currentStep ?? nextStep ?? 0),
			module,
			onClose,
		};
	}, [currentStep, handleStartOnboarding, module, nextStep, onClose, stepsWithAction]);

	return (
		<OnboardingContext.Provider value={value}>
			<>
				{children}
				<Tour
					open={!!module && isNextStepInitialized && !isFetching}
					current={currentStep ?? undefined}
					onClose={onClose}
					onFinish={onFinish}
					steps={steps}
					zIndex={10002}
					onChange={handleStepChange}
					disabledInteraction={!stepsWithAllowedInteractions?.includes(currentStep ?? 0)}
					indicatorsRender={indicatorRender}
					gap={{ radius: 6 }}
				/>
			</>
		</OnboardingContext.Provider>
	);
};

export default function OnboardingConsumer(): TOnboardingContextReturnType | undefined {
	const context = useContext(OnboardingContext);
	const isPublicRoute = useIsIncludesInPathname({ path: ERoutes.public });

	if (isPublicRoute) {
		return undefined;
	}

	if (context === undefined) {
		throw new Error('OnboardingConsumer must be used within a OnboardingProvider');
	}
	return context;
}
