import { useMemo } from 'react';

import { TourProps, Typography } from 'antd';
import cover1 from 'assets/images/sidemenu-poster-1.jpg';
import cover2 from 'assets/images/sidemenu-poster-2.jpg';
import cover3 from 'assets/images/sidemenu-poster-3.jpg';
import { ONBOARDING_GUIDE_MODULES } from 'shared/enums/onboardingGuideModules';
import { TRefsObject } from 'shared/types/onboardingTypes';

import { TOnboardingConfig } from '../hooks/useOnboardingConfigMap';
import { getFilteredSteps } from '../lib';
import styles from '../ui/SideMenuModule.module.scss';

const posterWidth = 700;

export const useSideMenuOnboardingConfig = (refs: TRefsObject): TOnboardingConfig => {
	return useMemo(() => {
		const steps: TourProps['steps'] = [
			{
				title: <></>,
				cover: (
					<>
						<div style={{ maxWidth: posterWidth }}>
							<img src={cover1} alt="cover" />
						</div>
						<div style={{ padding: '0 16px' }}>
							<Typography.Paragraph style={{ fontWeight: 600, margin: '16px 0 8px' }}>
								Have an idea or initiative?
							</Typography.Paragraph>
							<Typography.Text>
								Create your first initiative from scratch or use our AI-enabled initiative builder.
							</Typography.Text>
						</div>
					</>
				),
				placement: 'rightTop',
				style: {
					width: posterWidth,
				},
				className: styles.tour,
			},
			{
				title: <></>,
				cover: (
					<>
						<div style={{ maxWidth: posterWidth }}>
							<img src={cover2} alt="cover" />
						</div>
						<div style={{ padding: '0 16px' }}>
							<Typography.Paragraph style={{ fontWeight: 600, margin: '16px 0 8px' }}>
								Need a specific tool?
							</Typography.Paragraph>
							<Typography.Text>
								Leverage a diverse range of tools to boost productivity and streamline business
								operations.
							</Typography.Text>
						</div>
					</>
				),
				placement: 'rightTop',
				style: {
					width: posterWidth,
				},
				className: styles.tour,
			},
			{
				title: <></>,
				cover: (
					<>
						<div style={{ maxWidth: posterWidth }}>
							<img src={cover3} alt="cover" />
						</div>
						<div style={{ padding: '0 16px' }}>
							<Typography.Paragraph style={{ fontWeight: 600, margin: '16px 0 8px' }}>
								View helpful reports
							</Typography.Paragraph>
							<Typography.Text>
								Access key industry and business process insights to support decision-making.
							</Typography.Text>
						</div>
					</>
				),
				placement: 'rightTop',
				style: {
					width: posterWidth,
				},
				className: styles.tour,
			},
		];

		const mappedSteps: TourProps['steps'] = getFilteredSteps({
			steps,
			refs,
			module: ONBOARDING_GUIDE_MODULES.SIDE_MENU,
		});

		return { steps: mappedSteps };
	}, [refs]);
};
