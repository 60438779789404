import Axios from 'axios';
import { isUUIDCheck } from 'shared/lib/isUUIDCheck';

import TokenService from '../api/token.service';

export type TAxiosErrorData = {
	message: string;
	responseStatus: string;
	1710869557: number;
};

export const API_HOST = process.env.REACT_APP_API_HOST;

const API_PREFIX = '/api/v1';

const axios = Axios.create({
	baseURL: `${API_HOST}`,
	withCredentials: false,
	headers: {
		'Content-Type': 'application/json',
	},
});

const engagementIgnorePaths = ['/auth/', '/public/', '/access/'];

axios.interceptors.request.use(
	(config) => {
		const tokenService = new TokenService();
		const token = tokenService.getLocalAccessToken();

		if (token && config.url !== '/access/refresh' && !config?.url?.includes('/public/')) {
			// @ts-ignore
			config.headers['Authorization'] = 'Bearer ' + token;
		}

		const engagementId = window.location.pathname.split('/').at(1);
		// @ts-ignore
		const isEngagementIdUUID = isUUIDCheck(engagementId);
		const shouldSetEngagementIdHeader =
			isEngagementIdUUID && !engagementIgnorePaths.some((path) => config?.url?.includes(path));

		if (shouldSetEngagementIdHeader) {
			// @ts-ignore
			config.headers['X-Engagement-Id'] = engagementId;
		}

		return config;
	},
	(error) => {
		return Promise.reject(error);
	},
);

const ignoreUrls = [`${API_PREFIX}/users/onboarding`, '/auth/login', '/access/user/preview'];

axios.interceptors.response.use(
	(res) => {
		return res;
	},
	async (err) => {
		const originalConfig = err.config;
		const tokenService = new TokenService();

		const getMsg = (obj): string =>
			obj?.response?.data?.message || obj?.message || 'Something went wrong';

		const errorHandler = (e): Promise<any> => {
			tokenService.removeToken();
			const msg = getMsg(e);
			window.location.href = `${window.location.origin}/login?error=${msg}`;
			return Promise.reject(e);
		};

		if (originalConfig?.url === '/access/refresh' && err.response) {
			return errorHandler(err);
		}

		const exist = ignoreUrls.includes(originalConfig?.url);
		if (!exist && err.response) {
			// user was deleted
			if (err.response?.data?.responseStatus === 'INVALID_TOKEN_EXCEPTION') {
				tokenService.removeToken();
			}

			if (err.response?.data?.responseStatus === 'SUBSCRIPTION_NOT_ACTIVE_EXCEPTION') {
				window.location.href = '/inactive_subscription';
			}

			// Access Token was expired
			const rfToken = tokenService.getLocalRefreshToken();
			if (err.response.status === 401 && !originalConfig._retry) {
				try {
					if (rfToken) {
						originalConfig._retry = true;
						const rs = await axios.post<TRefreshToken>(
							'/access/refresh',
							{},
							{
								headers: {
									Authorization: 'Bearer ' + rfToken,
								},
							},
						);
						const { accessToken, refreshToken } = rs.data;
						tokenService.updateLocalAccessToken(accessToken);
						tokenService.updateLocalRefreshToken(refreshToken);
						return axios(originalConfig);
					}
					return errorHandler(err);
				} catch (_error) {
					return errorHandler(err);
				}
			}
		}
		return Promise.reject(err);
	},
);

type TRefreshToken = {
	accessToken: string;
	expiresIn: number;
	refreshToken: string;
};

export type TServerErrorResponse = {
	message: string;
	responseStatus: string;
	timestamp: number;
	messageParams: string[];
};

export { axios as default, API_PREFIX };
