import { useMemo } from 'react';

import { TourProps } from 'antd';
import { ONBOARDING_GUIDE_MODULES } from 'shared/enums/onboardingGuideModules';
import { TRefsObject } from 'shared/types/onboardingTypes';

import { TOnboardingConfig } from '../hooks/useOnboardingConfigMap';
import { getFilteredSteps } from '../lib';

export const useStoryboardPartOneOnboardingConfig = (refs: TRefsObject): TOnboardingConfig => {
	return useMemo(() => {
		const steps: TourProps['steps'] = [
			{
				title: 'Table',
				placement: 'top',
				description: (
					<p>Each storyboard created will be listed here. The titles wills have a hyperlink.</p>
				),
			},
			{
				title: 'Filter',
				placement: 'right',
				description: <p>Filter content by searching for a title or selecting filter parameters.</p>,
			},
			{
				title: 'Button',
				placement: 'bottomRight',
				description: <p>Add a storyboard here.</p>,
			},
			{
				title: 'Action Button',
				placement: 'bottomRight',
				description: <p>Manage tags and categories for storyboards.</p>,
			},
		];

		const mappedSteps: TourProps['steps'] = getFilteredSteps({
			steps,
			refs,
			module: ONBOARDING_GUIDE_MODULES.STORYBOARD_1,
		});

		return { steps: mappedSteps };
	}, [refs]);
};
