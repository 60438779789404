export enum ONBOARDING_GUIDE_MODULES {
	ACTION_PLANS = 'ACTION_PLANS',
	DIAGRAMS_1 = 'DIAGRAMS_1',
	DIAGRAMS_2 = 'DIAGRAMS_2',
	FILE_EXPLORER = 'FILE_EXPLORER',
	FORECAST_1 = 'FORECAST_1',
	FORECAST_2 = 'FORECAST_2',
	HOME_WALKTHROUGH = 'HOME_WALKTHROUGH',
	INDUSTRY_REPORTS = 'INDUSTRY_REPORTS',
	PROCESS_REPORTS = 'PROCESS_REPORTS',
	OPERATING_MODEL = 'OPERATING_MODEL',
	OPTIMIZATION = 'OPTIMIZATION',
	PLANS_1 = 'PLANS_1',
	PLANS_2 = 'PLANS_2',
	PROJECTS_1 = 'PROJECTS_1',
	PROJECTS_2 = 'PROJECTS_2',
	RETROSPECTIVES = 'RETROSPECTIVES',
	STORYBOARD_1 = 'STORYBOARD_1',
	STORYBOARD_2 = 'STORYBOARD_2',
	ENGAGEMENTS = 'ENGAGEMENTS',
	WORKSPACES = 'WORKSPACES',
	SIDE_MENU = 'SIDE_MENU',
}
