import { useMemo } from 'react';

import { TourProps } from 'antd';
import { ONBOARDING_GUIDE_MODULES } from 'shared/enums/onboardingGuideModules';
import { TRefsObject } from 'shared/types/onboardingTypes';

import { TOnboardingConfig } from '../hooks/useOnboardingConfigMap';
import { getFilteredSteps } from '../lib';

export const useEngagementsOnboardingConfig = (refs: TRefsObject): TOnboardingConfig => {
	return useMemo(() => {
		const steps: TourProps['steps'] = [
			{
				title: 'Engagements',
				description: <p>Switch between workspaces (engagements).</p>,
			},
			{
				title: 'Configure',
				placement: 'right',
				description: (
					<p>
						Adjust the configuration of the side menu. Hide items, change names, and arrange as
						needed.
					</p>
				),
			},
			{
				title: 'Side Menu',
				placement: 'topRight',
				description: <p>Expand or collapse the side menu.</p>,
			},
		];

		const mappedSteps: TourProps['steps'] = getFilteredSteps({
			steps,
			refs,
			module: ONBOARDING_GUIDE_MODULES.ENGAGEMENTS,
		});

		return { steps: mappedSteps, stepsWithAction: [0, 1] };
	}, [refs]);
};
