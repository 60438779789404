import { useMemo } from 'react';

import InitializationConsumer from 'shared/context/initialization';
import { ERoutes, ERoutesFullPath } from 'shared/enums/routes';
import { SUBSCRIPTION_STATUSES } from 'shared/enums/subscriptionStatus';
import AuthConsumer from 'shared/guards/auth.guard';
import { SUBSCRIPTIONS_PLANS } from 'shared/types/subscriptionPlansTypes';

export const useGetNavigatePathDueToPlan = (): {
	navigatePath: ERoutes | ERoutesFullPath;
} => {
	const { user } = AuthConsumer();
	const { initialization } = InitializationConsumer();

	const navigatePath = useMemo(() => {
		if (initialization && initialization?.subscriptionStatus !== SUBSCRIPTION_STATUSES.ACTIVE) {
			return ERoutesFullPath.inactive_subscription;
		}

		if (
			initialization &&
			initialization?.subscriptionPlans.includes(SUBSCRIPTIONS_PLANS.ENTERPRISE)
		) {
			return ERoutes.home;
		}

		return ERoutes.workspaces;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user, initialization?.subscriptionPlans]);

	return { navigatePath };
};
