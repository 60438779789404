import { useMemo } from 'react';

import { TourProps } from 'antd';
import { ONBOARDING_GUIDE_MODULES } from 'shared/enums/onboardingGuideModules';
import { TRefsObject } from 'shared/types/onboardingTypes';

import { TOnboardingConfig } from '../hooks/useOnboardingConfigMap';
import { getFilteredSteps } from '../lib';

export const usePlansPartTwoOnboardingConfig = (refs: TRefsObject): TOnboardingConfig => {
	return useMemo(() => {
		const steps: TourProps['steps'] = [
			{
				title: 'Navigation',
				placement: 'bottomLeft',
				description: <p>Share general thoughts about the plan.</p>,
			},
			{
				title: 'Navigation',
				placement: 'bottom',
				description: <p>Provide details to help inform the AI plan build.</p>,
			},
			{
				title: 'Navigation',
				placement: 'bottomRight',
				description: <p>Leverage AI to generate plan.</p>,
			},
		];

		const mappedSteps: TourProps['steps'] = getFilteredSteps({
			steps,
			refs,
			module: ONBOARDING_GUIDE_MODULES.PLANS_2,
		});

		return { steps: mappedSteps };
	}, [refs]);
};
