import { useMemo } from 'react';

import { TourProps } from 'antd';
import { ONBOARDING_GUIDE_MODULES } from 'shared/enums/onboardingGuideModules';
import AuthConsumer from 'shared/guards/auth.guard';
import { TRefsObject } from 'shared/types/onboardingTypes';

import { TOnboardingConfig } from '../hooks/useOnboardingConfigMap';
import { getFilteredSteps } from '../lib';

export const useForecastPartOneOnboardingConfig = (refs: TRefsObject): TOnboardingConfig => {
	const { user } = AuthConsumer();

	return useMemo(() => {
		const steps: TourProps['steps'] = [
			{
				title: 'Table',
				placement: 'top',
				description: <p>All forecasts are listed here.</p>,
			},
			{
				title: 'Button',
				placement: 'left',
				description: <p>Add forecast.</p>,
			},
			{
				title: 'Action Button',
				placement: 'bottomRight',
				description: <p>Manage tags, categories, and archives.</p>,
			},
			{
				title: 'Action Button',
				placement: 'bottomRight',
				description: <p>Edit forecasts.</p>,
				target: user?.isCanBeEditForecastPage
					? refs?.get(`${ONBOARDING_GUIDE_MODULES.FORECAST_1}-4`)?.current
					: null,
			},
		];

		const mappedSteps: TourProps['steps'] = getFilteredSteps({
			steps,
			refs,
			module: ONBOARDING_GUIDE_MODULES.FORECAST_1,
		});

		return { steps: mappedSteps };
	}, [refs, user]);
};
