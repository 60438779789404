import { useState } from 'react';

import { createQueryKeyStore } from '@lukemorales/query-key-factory';
import axios, { API_PREFIX } from 'shared/appConfigs/axios';
import { SUBSCRIPTION_STATUSES } from 'shared/enums/subscriptionStatus';
import { TDetail } from 'shared/types/dataDetailsType';

import InitializationPropertiesModel from '../models/initialization-properties.model';

interface IDynamicObject {
	[key: string]: string;
}

type TMeAuthorities = {
	superUserFunctionalRoleTypes: IDynamicObject;
	businessUnitSubTypes: IDynamicObject;
	functionalAreasTypes: IDynamicObject;
	headquartersSubTypes: IDynamicObject;
	organizationTypes: IDynamicObject;
	systemRoleTypes: IDynamicObject;
	usaStateTypes: IDynamicObject;
	userFunctionalRoleTypes: IDynamicObject;
	subscriptionStatus: keyof typeof SUBSCRIPTION_STATUSES;
};

export type TAllDetails = {
	organization: TDetail;
	plans: TDetail[];
	projects: TDetail[];
	optimizations: TDetail[];
	diagrams: TDetail[];
	storyboards: TDetail[];
};

export const getInitialization = async (): Promise<TMeAuthorities> => {
	const { data } = await axios.get<TMeAuthorities>(`${API_PREFIX}/initialization`);
	return data;
};

export type ReturnInitialization = {
	initialization: InitializationPropertiesModel | null;
	setInitialization: (initialization: InitializationPropertiesModel) => void;
	initializationLoading: boolean;
	refetchInitialization?: any;
};

export const useInitialization = (): ReturnInitialization => {
	const [initialization, setInitialization] = useState<InitializationPropertiesModel | null>(null);

	return {
		initialization,
		setInitialization,
		initializationLoading: false,
	};
};

export const initializationQueryKeys = createQueryKeyStore({
	initialization: {
		details: (arg) => [arg],
	},
});

export const getAllDetails = async (): Promise<TAllDetails> => {
	const { data } = await axios.get<TAllDetails>(`${API_PREFIX}/initialization/options`);
	return data;
};
