import IStorage from '../interfaces/storage.interface';

class TokenLocalStorageService implements IStorage {
	delete(key: string): void {
		localStorage.removeItem(key);
	}

	get(key: string): string | undefined {
		// @ts-ignore
		return localStorage.getItem(key);
	}

	set(key: string, value): void {
		localStorage.setItem(key, value);
	}
}

export default TokenLocalStorageService;
