import { memo } from 'react';
import { FC } from 'react';

import bg from 'assets/images/auth-bg.png';
import logo from 'assets/images/svg/logo.svg';

import './AuthLayout.scss';

type TProps = {
	children: JSX.Element;
};

const AuthLayout: FC<TProps> = (props): JSX.Element => {
	const { children } = props;
	return (
		<div className="auth-layout">
			<div className="auth-layout__bg">
				<img src={bg} alt="bg" />
			</div>
			<div className="auth-layout__col">
				<div className="auth-layout__widget">
					<strong className="auth-layout__logo">
						<img src={logo} alt="corvus logo" />
					</strong>
					{children}
				</div>
			</div>
		</div>
	);
};

export default memo(AuthLayout);
